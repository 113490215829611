<template>
  <svg
    class="font-icon"
    style="fill: none"
    width="108"
    height="97"
    viewBox="0 0 108 97"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="13" y="9" width="81" height="43" rx="10" fill="#ACD1C6" />
    <path
      d="M85.5 16.1667H22.5C17.5294 16.1667 13.5 19.7858 13.5 24.2501V80.8334C13.5 85.2977 17.5294 88.9167 22.5 88.9167H85.5C90.4706 88.9167 94.5 85.2977 94.5 80.8334V24.2501C94.5 19.7858 90.4706 16.1667 85.5 16.1667Z"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M72 8.08325V24.2499"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36 8.08325V24.2499"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5 40.4167H94.5"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "EvenementsVignetteIcon",
};
</script>
