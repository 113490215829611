<template>
  <b-container class="contact-card">
    <b-row class="mt-3" align-v="center">
      <b-col>
        <h4>{{ item.attributes.nom }}</h4>
        <em>{{ fonction }}</em>
        <hr />
      </b-col>
      <b-col cols="auto" class="d-none d-md-block">
        <b-avatar :src="avatarSrc" :alt="avatarAlt" size="5rem"></b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="contact-description mb-3 mb-md-0">
        {{ item.attributes.description }}
      </b-col>
    </b-row>
    <b-row class="contact-footer">
      <b-col v-if="item.attributes.numeroPortable" cols="12" md="6">
        <PhoneNumberComponent
          classes="d-inline-block mr-3"
          :icon="true"
          :value="item.attributes.numeroPortable"
        ></PhoneNumberComponent>
      </b-col>
      <b-col v-if="item.attributes.numeroFixe" cols="12" md="6">
        <PhoneNumberComponent
          :icon="true"
          :value="item.attributes.numeroFixe"
        ></PhoneNumberComponent>
      </b-col>
      <b-col v-if="item.attributes.email" cols="12">
        <EmailComponent
          :icon="true"
          :value="item.attributes.email"
        ></EmailComponent>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PhoneNumberComponent from "../PhoneNumberComponent.vue";
import EmailComponent from "../EmailComponent.vue";
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiContactComponent",
  props: ["item"],
  components: { PhoneNumberComponent, EmailComponent },
  computed: {
    fonction() {
      return (
        this.item.attributes.fonction ??
        this.item.attributes.equipe?.data?.attributes.libelle ??
        ""
      );
    },
    avatarSrc() {
      return StrapiService.getAssetUrl(
        this.item.attributes.avatar?.data?.attributes.url
      );
    },
    avatarAlt() {
      return this.item.attributes.avatar?.data?.attributes.alternativeText;
    },
  },
};
</script>
