<template>
  <section>
    <b-card v-if="computedWidget" no-body class="actualite-card-widget">
      <b-row no-gutters>
        <b-col>
          <b-card-body :title="item.attributes.titre">
            <b-card-text>
              <b-icon-calendar></b-icon-calendar>
              Le {{ datePublication }}
            </b-card-text>
            <b-card-text>
              <b-link href="#" @click="enSavoirPlus">
                <b-icon-arrow-right></b-icon-arrow-right>
                Voir les d&eacute;tails
              </b-link>
            </b-card-text>
          </b-card-body>
        </b-col>
        <b-col
          cols="auto"
          class="d-none d-md-block card-icon"
          align-self="center"
        >
          <ActualitesVignetteIcon></ActualitesVignetteIcon>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-else
      class="actualite-card"
      :title="item.attributes.titre"
      :img-src="couvertureSrc"
      :img-alt="couvertureAlt"
      img-top
      role="button"
      @click="enSavoirPlus"
    >
      <b-card-text>
        <b-icon-calendar></b-icon-calendar>
        Le {{ datePublication }}
      </b-card-text>
      <b-card-text class="text-justify">
        {{ item.attributes.description }}
      </b-card-text>
      <template #footer>
        <b-button pill variant="primary" @click="enSavoirPlus">
          En savoir plus
        </b-button>
      </template>
    </b-card>
  </section>
</template>

<script>
import ActualitesVignetteIcon from "../icons/ActualitesVignetteIcon.vue";
import { actualite } from "../../services/strapi.service";
export default {
  name: "StrapiActualiteCard",
  components: { ActualitesVignetteIcon },
  props: ["item", "widget"],
  computed: {
    ...actualite.computed,
    computedWidget() {
      return this.widget === true || this.widget === "true";
    },
  },
  methods: {
    enSavoirPlus() {
      this.$router.push({ name: "actualite", params: { id: this.item.id } });
    },
  },
};
</script>
