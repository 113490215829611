<template>
  <b-container fluid class="px-0">
    <b-row v-if="rotatedContent.length === 0">
      <b-col class="text-center">
        <slot name="empty">Aucun contenu &agrave; afficher</slot>
      </b-col>
    </b-row>
    <b-row v-else align-v="center">
      <!-- Boutons de navigation -->
      <b-col cols="6" md="auto" order-md="1" class="p-0 text-center">
        <b-button
          class="p-2 m-0"
          variant="link"
          :disabled="leftDisabled"
          @click="left"
        >
          <b-icon-chevron-left></b-icon-chevron-left>
        </b-button>
      </b-col>
      <b-col cols="6" md="auto" order-md="3" class="p-0 text-center">
        <b-button
          class="p-2 m-0"
          variant="link"
          :disabled="rightDisabled"
          @click="right"
        >
          <b-icon-chevron-right></b-icon-chevron-right>
        </b-button>
      </b-col>
      <!-- Contenu des slides-->
      <b-col class="slide-container" order-md="2">
        <section class="slide-content d-block d-md-none">
          <slot :item="rotatedContent[0]"></slot>
        </section>
        <section
          class="slide-content d-none d-md-inline-block mr-3"
          v-for="(item, index) in rotatedContent"
          :key="index"
        >
          <slot :item="item"></slot>
        </section>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ContentSliderComponent",
  /**
   * items = liste des items à afficher
   * noLoop = empêche le slider de faire boucler les items en début/fin de parcours
   */
  props: ["items", "noLoop"],
  data: () => ({
    startIndex: 0,
  }),
  computed: {
    noLoopAllowed() {
      return this.noLoop === true || this.noLoop === "true";
    },
    leftDisabled() {
      return this.noLoopAllowed && this.startIndex <= 0;
    },
    rightDisabled() {
      return this.noLoopAllowed && this.startIndex >= this.items.length - 1;
    },
    rotatedContent() {
      if (this.items.length === 0 || this.startIndex === 0) {
        return this.items;
      }
      return this.noLoopAllowed
        ? this.items.slice(this.startIndex)
        : this.items
            .slice(this.startIndex)
            .concat(this.items.slice(0, this.startIndex));
    },
  },
  methods: {
    left() {
      this.startIndex =
        this.startIndex > 0 ? this.startIndex - 1 : this.items.length - 1;
    },
    right() {
      this.startIndex =
        this.startIndex < this.items.length - 1 ? this.startIndex + 1 : 0;
    },
  },
};
</script>

<style scoped>
.slide-container {
  white-space: nowrap;
  overflow: hidden;
}
.slide-content {
  white-space: normal;
}
</style>
