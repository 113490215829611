<template>
  <svg
    class="font-icon"
    style="fill: none"
    width="102"
    height="111"
    viewBox="0 0 102 111"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M68 18.5H76.5C78.7543 18.5 80.9163 19.4746 82.5104 21.2093C84.1045 22.944 85 25.2967 85 27.75V92.5C85 94.9533 84.1045 97.306 82.5104 99.0407C80.9163 100.775 78.7543 101.75 76.5 101.75H25.5C23.2457 101.75 21.0837 100.775 19.4896 99.0407C17.8955 97.306 17 94.9533 17 92.5V27.75C17 25.2967 17.8955 22.944 19.4896 21.2093C21.0837 19.4746 23.2457 18.5 25.5 18.5H34"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="26" y="17" width="50" height="62" rx="10" fill="#ACD1C6" />
    <path
      d="M63.75 10.25H38.25C35.9028 10.25 34 12.3207 34 14.875V24.125C34 26.6793 35.9028 28.75 38.25 28.75H63.75C66.0972 28.75 68 26.6793 68 24.125V14.875C68 12.3207 66.0972 10.25 63.75 10.25Z"
      fill="#ACD1C6"
      stroke="#054643"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ActualitesVignetteIcon",
};
</script>
